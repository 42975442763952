@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f5f5f5;
}
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

*{
  font-family: 'Ubuntu', sans-serif;
}